<template>
  <menu-main> </menu-main>
  <div id="exportAdd">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div v-show="exportRecordingsPriv" id="confProg" class="container">
        <div class="row">
          <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
            <div class="alert alert-custom" id="msg-alert">
              <p>{{ errorMessage }}</p>
            </div>
            <ul class="nav nav-tabs nav-fill" role="tablist">
              
            </ul>
            <button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido" style="border: 0px;">
              <!--TAB1-->
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="conf_box">
                  <div class="nom_input">
                    <p>{{ $t('common.date') }}</p>
                    <input class="text_input"
                      v-model="date"
                      id="export_date"
                      type="text"
                      disabled
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('common.name') }}</p>
                    <input class="text_input"
                      v-model="name"
                      id="export_name"
                      type="text"
                      :placeholder="$t('exports.export_name')"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('common.start') }}</p>
                    <Datepicker
                      v-model="startPicker"
                      timePicker
                      enableSeconds
                      :minTime="minStartPicker"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('common.end') }}</p>
                    <Datepicker
                      v-model="endPicker"
                      timePicker
                      enableSeconds
                      :maxTime="maxEndPicker"
                    />
                  </div>
                </div>
                <!--end conf_box-->
              </div>
            </div>
            <!--end npContenido-->
            <div 
              :class="!loaded ? 'disabled_btn_guardar' : 'btn_guardar'">
              <button :disabled="!loaded" @click.prevent="save">
                <a href="#">{{ $t('exports.export') }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <menu-footer active-state="recordings"> </menu-footer>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import $ from "jquery";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
export default {
  name: "ExportAdd",
  components: {
    Datepicker,
  },
  props: {
    paramRecId: String,
  },
  data() {
    return {
      loaded: false,
      saving: false,
      //error: false,
      exportRecordingsPriv: false,
      errorMessage: "",
      recId: -1,
      camId: -1,
      name: "",
      endTime: "",
      startTime: "",
      date: "",
      startPicker: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      minStartPicker: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      endPicker: {
        hours: 23,
        minutes: 59,
        seconds: 59,
      },
      maxEndPicker: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  methods: {
    showMsgAlert() {
      $("#msg-alert")
        .fadeTo(200, .80, function() {
					setTimeout(function() {
						$("#msg-alert").fadeTo(200,0);
						},2000)
				});
    },
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "RecordingsMain" });
      this.$router.go(-1);
    },
    save() {
      console.log(
        "Export recording",
        this.name,
        this.datePicker,
        this.date,
        this.startPicker,
        this.endPicker
      );
      //this.error = false;
      this.errorMessage = "";
      const initDate = this.getDateAPIFormat(this.startPicker, this.startTime);
      const endDate = this.getDateAPIFormat(this.endPicker, this.endTime);
      console.log("initDate:", initDate);
      console.log("endDate:", endDate);
      if (this.verifyData(initDate, endDate)) {
        this.addExport(initDate, endDate);
      }
      else {
        this.showMsgAlert();
      }
    },
    verifyData(initDate, endDate) {
      if (this.name === "") {
        //this.error = true;
        this.errorMessage = this.$t('exports.export_name_required');
        return false;
      }
      console.log("initDate:",initDate);
      console.log("startTime:",this.startTime);
      console.log("endDate:",endDate);
      console.log("startTime:",this.endTime);
      console.log("initDate:",parseFloat(initDate));
      console.log("startTime:",parseFloat(this.startTime));
      console.log("endDate:",parseFloat(endDate));
      console.log("startTime:",parseFloat(this.endTime));
      if (parseFloat(initDate) < parseFloat(this.startTime)) {
        //this.error = true;
        this.errorMessage = this.$t('exports.invalid_start_time');
        return false;
      }
      if (parseFloat(endDate) > parseFloat(this.endTime)) {
        //this.error = true;
        this.errorMessage = this.$t('exports.invalid_end_time');
        return false;
      }
      if (parseFloat(endDate) <= parseFloat(initDate)) {
        //this.error = true;
        this.errorMessage = this.$t('exports.invalid_end_time');
        return false;
      }
      if (parseFloat(initDate) >= parseFloat(endDate)) {
        //this.error = true;
        this.errorMessage = this.$t('exports.invalid_start_time');
        return false;
      }
      return true;
    },
    async addExport(initDate, endDate) {
      this.saving = true;
      
      try {
        await this.$root.dataManager.exportRecording(this.recId,this.name,initDate, endDate);
        console.log("addExport OK");
        this.saving = false;
        this.errorMessage = "";
        this.$router.push({ name: "ExportsMain" });
      } catch (error) {
        console.log("addExport error");
        console.error(error);
        this.saving = false;
        //this.error = true;
        this.errorMessage = this.$t('exports.review_export_params');
        this.showMsgAlert();
      }
    },

    
    async getRecording(id) {
      this.loaded=false;
      try {
        const response = await this.$root.dataManager.loadRecording(id);
        console.log("getRecording OK");
        this.camId = response.rec.camId;
        this.name = response.rec.camName;
        this.endTime = response.rec.endTime;
        this.startTime = response.rec.startTime;

        this.date = this.getDate(this.startTime);

        this.startPicker.hours = this.getHour(this.startTime);
        this.startPicker.minutes = this.getMinutes(this.startTime);
        this.startPicker.seconds = this.getSeconds(this.startTime);
        this.minStartPicker.hours = this.getHour(this.startTime);
        this.minStartPicker.minutes = this.getMinutes(this.startTime);
        this.minStartPicker.seconds = this.getSeconds(this.startTime);

        this.endPicker.hours = this.getHour(this.endTime);
        this.endPicker.minutes = this.getMinutes(this.endTime);
        this.endPicker.seconds = this.getSeconds(this.endTime);
        this.maxEndPicker.hours = this.getHour(this.endTime);
        this.maxEndPicker.minutes = this.getMinutes(this.endTime);
        this.maxEndPicker.seconds = this.getSeconds(this.endTime);
        this.loaded = true;
        console.log("recording name:", this.name);
        console.log("recording date:", this.getDate(this.startTime));
        console.log("recording starttime:", this.getTime(this.startTime));
        console.log("recording endtime:", this.getTime(this.endTime));
        
      } catch (error) {
        console.log("getRecording error");
        console.error(error);
        //this.error = true;
      }
    },

    getDateAPIFormat(timePicker, startTime) {
      const time = this.parseTime(timePicker);
      console.log("time:", time);
      const full_date =
        this.getYear(startTime) +
        this.getMonth(startTime) +
        this.getDay(startTime) +
        time;
      console.log("full_date:", full_date);
      return full_date;
    },
    getDate(tm) {
      return (
        tm.substring(6, 8) + "/" + tm.substring(4, 6) + "/" + tm.substring(0, 4)
      );
    },
    getDay(tm) {
      return tm.substring(6, 8);
    },
    getMonth(tm) {
      return tm.substring(4, 6);
    },
    getYear(tm) {
      return tm.substring(0, 4);
    },
    getTime(tm) {
      if (tm) {
        return tm.substring(8, 10) + ":" + tm.substring(10, 12);
      } else {
        return "---";
      }
    },
    getHour(tm) {
      if (tm) {
        return parseInt(tm.substring(8, 10));
      } else {
        return 0;
      }
    },
    getMinutes(tm) {
      if (tm) {
        return parseInt(tm.substring(10, 12));
      } else {
        return 0;
      }
    },
    getSeconds(tm) {
      if (tm) {
        return parseInt(tm.substring(12, 14));
      } else {
        return 0;
      }
    },
    parseTime(time) {
      let hour = time.hours;
      let min = time.minutes;
      let sec = time.seconds;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      return hour + "" + "" + min + "" + sec;
    },
  },
  mounted() {
    console.log("ExportAdd with recid:", this.paramRecId);
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    $("#msg-alert").hide();
    this.exportRecordingsPriv = userPriv["exportRecordings"];
    this.recId = parseInt(this.paramRecId);
    this.getRecording(this.recId);
    document.getElementById("export_name").focus();
    document.getElementById("export_name").blur();
  },
};
</script>

<style scoped>
.container .nav-tabs {
    border-bottom: none!important;
}
#npContenido {
	padding-top:40px;
	padding-bottom: 25px;
}
</style>