<template>
  <menu-main></menu-main>

  <div id="labels">
    <header-main> </header-main>

    <div class="cuerpo_principal">
      <div v-show="editLabelsPriv" class="mosaico_cam">
        <div class="main_content">
          <div class="grid_busc">
            <form class="buscador" @submit.prevent>
              <input style="padding-right: 40px;"
                id="search"
                type="search"
                v-model="search"
                :placeholder="$t('common.search')"
              />
              <span class="material-icons refresh-button"
                @click="refresh">refresh</span>
            </form>
            
          </div>
        </div>
      </div>


      <div class="container">
        <p v-if="!editLabelsPriv" class="no_data_msg">
          {{ $t('common.no_privileges') }}
        </p>
        <p v-if="editLabelsPriv && this.$root.dataManager.labelsLoaded && !this.$root.dataManager.labels.length" class="no_data_msg">
          {{ $t('labels.no_labels') }}
        </p>
        <div v-if="editLabelsPriv && editLabelsPriv && this.$root.dataManager.labelsLoaded && this.$root.dataManager.labels.length" class="row">
          <div id="tgrab" class="col-12 offset-0 col-md-10 offset-md-1">
            <div class="table-responsive">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{{ $t('common.name') }}</th>
                    <th scope="col">{{ $t('labels.alerts_enabled') }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <!--<template v-for="(label, index) in filterLabels" :key="label.id">-->
                    <label-item v-for="(label, index) in filterLabels" :key="label.id"
                      :index="index"
                      :paramId="label.id"
                      :alarmState="label.alarmState"
                      :name="label.name"
                      @delete-event="showDeleteConfirmation"
                      @edit-event="editLabel"
                    >
                    </label-item>
                  <!--</template>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="editLabelsPriv" id="btnAgregar">
<button
            v-show="editLabelsPriv"
            @click="addLabel"
            class="agregar_opcion"
          >
          <span class="material-icons">add</span>

            <!--<strong>Añadir<br />Cámara</strong>-->
						<!--<i class="fa fa-plus"/>-->

          </button>
 
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state="labels"></menu-footer>
</template>

<script>
import LabelItem from "./LabelItem.vue";
//import DataManager from "../../assets/custom/js/DataManager.js";
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");

export default {
  name: "LabelsMain",
  components: {
    LabelItem,
  },
  data() {
    return {
      //labels: [],
      search: "",
      /*error: false,
      loaded: false,*/
      editLabelsPriv: false,
    };
  },
  computed: {
    filterLabels() {
      const filter1 = this.filterLabelsByName();
      if (filter1) {
        return [...new Set([...filter1])];
      }
      //return this.labels;
      return this.$root.dataManager.labels;
    },
  },

  methods: {
    async refresh() {
      console.log("refresh");
      this.$root.dataManager.loadLabels(true);
    },
    filterLabelsByName() {
      return this.search
        ? this.$root.dataManager.labels.filter((label) =>
          label.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },

    showDeleteConfirmation(id, index) {
      console.log("showDeleteConfirmation:", id, index);
      Swal.fire({
        /*title: this.recordings[index].camName,*/
        text: this.$t('labels.label_del_confirmation', {labelname: this.$root.dataManager.labels[index].name}),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        position: "center",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Delete:", id, index);
          this.deleteLabel(id);
        }
      });
    },

    async deleteLabel(id) {
      try {
        const response = await this.$root.dataManager.deleteInstallation(id);
        console.log ("deletelabel return");
        console.log(response);
        if (response.result==0) {
          console.log("label deleted OK");
          //await this.$root.dataManager.loadInstallations(true);
        }
      } catch (error) {
        console.log("label delete ERROR", error);
      }
    },

    editLabel(id, index) {
      console.log("Edit label", id, index);
      this.$router.push({ name: "LabelEdit", params: { editId: id } });
    },

    async addLabel() {
      console.log("add label");
      this.$router.push({ name: "LabelAdd" });
    },

  },
  mounted() {
    document.documentElement.style.scrollBehavior = 'auto';
    console.log("LabelsMain");
    document.getElementById("search").focus();
    document.getElementById("search").blur();
    document.title = this.$t('common.labels');
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.editLabelsPriv = userPriv["editCameras"];
    //this.getLabels();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>