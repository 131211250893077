<template>
  <menu-main></menu-main>
  <div id="languageChange">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div id="confUser" class="container">
        <div class="row">
          <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
            <ul class="nav nav-tabs nav-fill" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="language-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#language"
                  type="button"
                  role="tab"
                  aria-controls="language"
                  aria-selected="true"
                >
                {{ $t('settings.language') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="notifications-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#notifications"
                  type="button"
                  role="tab"
                  aria-controls="notifications"
                  aria-selected="false"
                >
                {{ $t('settings.notifications') }}
                </button>
              </li>  
            </ul>
            <button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido" style="border: 0px;">
              <div
                class="tab-pane fade show active"
                id="language"
                role="tabpanel"
                aria-labelledby="informacion-tab">
                <div class="conf_box">
                  <div class="nom_input">
                    <p>{{ $t('settings.language') }}</p>
                    <div class="conf_tipo">
                      <select class="dropdown_select"
                        v-model="languageSelected"
                        id="language"
                        name="language"
                      >
                        <option
                          v-for="language in this.$root.$i18n.availableLocales"
                          :key="language"
                          :value="language"
                        >
                          {{ $t('languages.'+language) }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="notifications"
                role="tabpanel"
                aria-labelledby="privileges-tab">
                <div class="sw_prog">
                  <div class="sw_contenedor">
                    <p>{{ $t('settings.motion_detection') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swMotionDetection"
                        v-model="motionDetection"
                        class="switch_input"
                      />
                      <label for="swMotionDetection" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor">
                    <p>{{ $t('settings.audio_detection') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swAudioDetection"
                        v-model="audioDetection"
                        class="switch_input"
                      />
                      <label for="swAudioDetection" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor">
                    <p>{{ $t('settings.connectivity_events') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swConnectivityEvents"
                        v-model="connectivityEvents"
                        class="switch_input"
                      />
                      <label for="swConnectivityEvents" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor">
                    <p>{{ $t('settings.label_configuration') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swLabelConfiguration"
                        v-model="labelConfiguration"
                        class="switch_input"
                      />
                      <label for="swLabelConfiguration" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor">
                    <p>{{ $t('settings.external_events') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swExternalEvents"
                        v-model="externalEvents"
                        class="switch_input"
                      />
                      <label for="swExternalEvents" class="switch_label"></label>
                    </div>
                  </div>
                </div>
              </div>
						
            </div>
            
            <div class="btn_guardar">
              <button @click.prevent="save">
                <a href="#">{{ $t('common.save') }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state=""></menu-footer>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "PasswordChange",
  computed: {
    
  },
  data() {
    return {
      error: false,
      saving: false,
      languageSelected: "",
      motionDetection: true,
      audioDetection: true,
      connectivityEvents: true,
      labelConfiguration: true,
      externalEvents: true,
    };
  },
  methods: {
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "CamerasMain" });
      console.log(this.$router);
      this.$router.go(-1);
    },
    
    save() {
      this.updateSettings();

      //console.log("Notifications " + this.motionDetection);
    },
    async updateSettings() {
      this.saving = true;
      console.log("updateLanguage");
      //const userEmail = JSON.parse(sessionStorage.getItem("userLogin")).email;
      try {
        this.$root.dataManager.setEnabledNotifications(this.motionDetection,this.audioDetection,this.connectivityEvents,this.labelConfiguration,this.externalEvents);
        await this.$root.dataManager.setLanguage(this.languageSelected);
        this.$root.$i18n.locale=this.languageSelected;
        this.saving = false;
        this.$router.go(-1);

      } catch (error) {
        console.log("updateLanguage error");
        console.error(error);
        this.error = true;
        this.errorMessage = this.$t('settings.error_updating_language');
      }
    }

  },
  mounted() {
    console.log("LanguageChange");
    this.languageSelected=this.$root.dataManager.language;
    this.motionDetection=this.$root.dataManager.notificationSettings.motionDetection;
    this.audioDetection=this.$root.dataManager.notificationSettings.audioDetection;
    this.connectivityEvents=this.$root.dataManager.notificationSettings.connectivityEvents;
    this.labelConfiguration=this.$root.dataManager.notificationSettings.labelConfiguration;
    this.externalEvents=this.$root.dataManager.notificationSettings.externalEvents;
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.nom_input>div
{
  position:relative;  
}
.nom_input>div i {
    padding-left: 8px;
    font-size: 20px;
    line-height: 1;
    vertical-align: top;
    padding-top: 2px;
}
.sw_prog .sw_contenedor p {
  width: 320px;
  text-align: left;
}
</style>